<div class="container bds-x-center bds-y-center bds-blur-bg">
    <div class="modal bds-container bds-shadow-3 bds-margin-3">
        <div class="modal-body">
            <div class="bds-container-header bds-shadow-2 bds-padding-2 bds-x-even_spread bds-y-center">
                <h2 *ngIf="!hasTicket">New Ticket</h2>
                <h2 *ngIf="hasTicket">Edit Ticket</h2>
                <div>
                    <button class="bds-button bds-button-flat bds-r-margin-1" (click)="handleCancel()">Cancel</button>
                    <button class="bds-button bds-button-success bds-shadow-1" (click)="handleSave()">Save</button>
                </div>
            </div>
            <div class="content">
                <div class="fields bds-padding-2">
                    <div class="bds-field-label">Title</div>
                    <input
                        #inputField
                        class="bds-input-field big-input bds-b-margin-2"
                        [(ngModel)]="title"
                    >
                    <div class="bds-field-label">External Link</div>
                    <input
                        class="bds-input-field big-input bds-b-margin-2"
                        placeholder="https://www.example.com"
                        [(ngModel)]="url"
                    >
                    <div class="bds-field-label">Description</div>
                    <textarea
                        class="bds-input-field big-input description bds-input-field-large bds-b-margin-2"
                        type="text"
                        [(ngModel)]="description"
                    ></textarea>
                    <div class="bds-field-label">Tag</div>
                    <select [ngModel]="tagId" (ngModelChange)="updateTag($event)" class="bds-input-field big-input bds-b-margin-2">
                        <option [ngValue]="tag.id" *ngFor="let tag of tags">{{tag.value}}</option>
                    </select>
                    <div class="bds-field-label">Group</div>
                    <select [ngModel]="groupId" (ngModelChange)="updateGroup($event)" class="bds-input-field big-input bds-b-margin-1">
                        <option [ngValue]="group.id" *ngFor="let group of groups"><div class="thing">{{group.value}}</div></option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
