<div class="container bds-l-margin-3 bds-r-margin-3 bds-y-center bds-x-even_spread">
    <div class="bds-y-center">
        <i class="main-icon fas fa-project-diagram bds-r-margin-1 bds-padding-1 bds-x-center bds-y-center bds-shadow-2"></i>
        <div class="map-title">
            {{ title }}
        </div>
    </div>
    <button class="bds-button bds-shadow-1" (click)="editMap()">
        <i class="fas fa-pen bds-r-margin-1"></i>
        Edit
    </button>
</div>
