<div class="container">
    <div class="container-top bds-shadow-3">
        <h1 class="bds-l-margin-1">Dependency<br>Mapper</h1>
    </div>
    <div class="container-bottom bds-t-margin-2 bds-l-margin-1">
        <ol>
            <li>
                <p class="instructions-p">Give your new dependency map a memorable name.<br>(You can change it later)</p>
                <input class="name-input bds-input-field bds-b-margin-2 bds-shadow-1" type="text" [(ngModel)]="title">
            </li>
            <li><button class="bds-button bds-button-success bds-shadow-1" (click)="createMap()">Get Started</button></li>
        </ol>
    </div>
    <!-- <div class="title"><h1>Dependency<br/>Mapper</h1></div>
    <button class="get-started bds-button bds-button-success bds-shadow-3" (click)="createMap()">Get Started</button> -->
</div>
